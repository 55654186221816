<template>
    <div class="box">
        <div class="null-page" v-show="yes"></div>
        <h2>
            会员信息
            <span style="font-size: 14px; float: right"
                ><img src="../../assets/images/alibaba/vip_icon.png" style="width: 12px; height: 12px" alt="" /> 您目前的会员等级是：<span style="color: #ed6030">{{ memberInfo.levelName }}</span></span
            >
        </h2>
        <div class="member-info" v-loading="loading">
            <div class="preview">
                <el-upload :action="uploadActionUrl" :show-file-list="false" :on-success="handleAvatarSuccess" class="upload">
                    <div class="img-wrap"><img :src="$img(memberInfo.userHeadImg)" @error="memberInfo.userHeadImg = defaultHeadImage" style="    border-radius: 50%;width: 50px;height: 40px;" />
					<img src="../../assets/images/car.png" style="width: 20px;margin-top: -40px;margin-left: 30px;">
					</div>
					
                    <p>{{ memberInfo.userName }}</p>
                </el-upload>
                <table>
                    <tr>
                        <td><label for="">用户名</label><input type="text" value="" v-model="memberInfo.userName" /></td>
                        <td>您的注册时间：{{ memberInfo.registerTime | formatDate }}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><label for="">会员名称</label><input type="text" value="" v-model="memberInfo.nickName" /></td>
                        <td><label for="">手机</label><input type="text" readonly="readonly" value="" v-model="memberInfo.tell" /></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><label for="">采购负责人</label><input type="text" value="" v-model="memberInfo.caigou_ren" /></td>
                        <td><label for="">手机</label><input type="text" value="" v-model="memberInfo.caigou_ren_phone" /></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><label for="">客户经理</label><input type="text" value="" v-model="memberInfo.manager" readonly="readonly" /></td>
                        <td><label for="">手机</label><input type="text" value="" v-model="memberInfo.managerPhone"/></td>
                        <td><label for="">QQ</label><input type="text" value="" v-model="memberInfo.managerQQ" /></td>
                        <td><label for="">邮箱</label><input type="text" value="" v-model="memberInfo.manageremail" /></td>
                    </tr>
                </table>
            </div>
            <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick"> -->
            <!-- <el-tab-pane label="基本信息" name="first"> -->
            <!-- <el-form ref="infoRef" :model="memberInfo" :rules="infoRules" label-width="80px">
                    <el-form-item label="用户名" v-if="memberInfo.userName">
                        <p>{{ memberInfo.userName }}</p>
                        <p>您的注册时间：2021年5月15日</p>
                    </el-form-item> -->
            <!-- <el-form-item label="邮箱">
							<p v-if="memberInfo.email">{{ memberInfo.email }}</p>
							<p v-else class="toBind" @click="$router.push({ path: '/member/security' })">去绑定</p>
						</el-form-item> -->
            <!-- <el-form-item label="手机号">
							<p v-if="memberInfo.tell">{{ memberInfo.tell }}</p>
							<p v-else class="toBind" @click="$router.push({ path: '/member/security' })">去绑定</p>
						</el-form-item>
						<el-form-item label="昵称" prop="nickName"><el-input v-model="memberInfo.nickName"></el-input></el-form-item> -->
            <!-- </el-form> -->
            <!-- <div class="btn"><el-button size="medium" type="primary" @click="saveInfo">保存</el-button></div> -->
            <!-- </el-tab-pane> -->
            <!-- <el-tab-pane label="头像照片" name="second"> -->

            <div>
                <div class="btn"><el-button style="margin-left: 97px; margin-top: 30px; float: left" size="medium" type="primary" @click="uploadHeadImg">保存信息</el-button></div>
                <div class="last_time">上次登录时间： {{ memberInfo.lastLoginTime | formatDate }}</div>
            </div>
            <!-- </el-tab-pane> -->
            <!-- </el-tabs> -->
            <div style="padding-top: 120px">
                <table>
                    <tr>
                        <td>
                          <a href="../../member/order_list?order_status=order_status_unconfirmed">
                                <div class="order-info" @click="goToOrderList()">
                                    <img src="../../assets/images/info/1.png" alt="" />
                                    <p class="number">{{ memberInfo.unconfirmed }} <span>个</span></p>
                                    <p class="explain">未确认订单</p>
                                </div>
                          </a>
                        </td>
                        <td>
                          <a href="../../member/order_list?order_status=order_status_unpaid">
                                <div class="order-info" @click="goToOrderList()">
                                    <img src="../../assets/images/info/2.png" alt="" />
                                    <p class="number">{{ memberInfo.unpaid }} <span>个</span></p>
                                    <p class="explain">未付款订单</p>
                                </div>
                          </a>
                        </td>
                        <td>
                          <a href="../../member/order_list?order_status=order_status_unshipped">
                              <div class="order-info" @click="goToOrderList()">
                                    <img src="../../assets/images/info/3.png" alt="" />
                                    <p class="number">{{ memberInfo.unshipped }} <span>个</span></p>
                                    <p class="explain">未发货订单</p>
                                </div>
                          </a>
                        </td>
                        <td>
                            <a href="../../member/book_list?order_status=uncomplete">
                              <div class="order-info">
                                    <img src="../../assets/images/info/4.png" alt="" />
                                    <p class="number">{{ memberInfo.uncomplete }} <span>个</span></p>
                                    <p class="explain">未完成预订单</p>
                                </div>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="../../member/cart"
                                ><div class="order-info">
                                    <img src="../../assets/images/info/5.png" alt="" />
                                    <p class="number">{{ cartCount }} <span>个</span></p>
                                    <p class="explain">购物车商品</p>
                                </div></a
                            >
                        </td>
                        <td>
                            <a href="../../member/collection"
                                ><div class="order-info">
                                    <img src="../../assets/images/info/6.png" alt="" />
                                    <p class="number">{{ memberInfo.favorites }} <span>个</span></p>
                                    <p class="explain">收藏夹商品数</p>
                                </div></a
                            >
                        </td>
                        <td>
                            <a href="../../member/order_list">
                                <div class="order-info">
                                    <img src="../../assets/images/info/7.png" alt="" />
                                    <p class="number">{{ memberInfo.fivemonth }} <span>个</span></p>
                                    <p class="explain">近5个月完成订单</p>
                                </div>
                            </a>
                        </td>
                        <td>
                            <a href="../../member/order_list"
                                ><div class="order-info">
                                    <img src="../../assets/images/info/8.png" alt="" />
                                    <p class="number" style="font-size: 1.2rem;">{{ memberInfo.allmoney }} <span>元</span></p>
                                    <p class="explain">已完成订单金额</p>
                                </div></a
                            >
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { info, nickName, headImg } from "@/api/member/info"
import Config from "@/utils/config"
import { mapGetters } from "vuex"
import { email } from "@/api/member/security"
import { cartList as apiCartList } from "@/api/goods/cart"

export default {
    name: "info",
    components: {},
    data: () => {
        return {
            memberInfo: {
                userHeadImg: "",
                userName: "", //账号
                nickName: "", //昵称
                email: "", //电子邮箱
                tell: "", //电话
                caigou_ren: "", //采购人
                caigou_ren_phone: "", //采购人电话
                number: "", //商品数量
                unconfirmed: "", //未确认订单
                unpaid: "", //未付款订单
                unshipped: "", //未发货订单
                uncomplete: "", //未完成订单
                // cartCount: "", //购物车商品
                favorites: "", //收藏夹商品数
                fivemonth: "", //近5个月完成订单
                allmoney: "", //已完成订单金额
                manager: "", //客户经理
                managerPhone: "", //客户经理手机
                managerQQ: "", //客户经理qq
                manageremail: "", //客户经理邮箱
                registerTime: "", //注册时间
                levelName: "", //会员等级
                lastLoginTime: "" //上次登录时间
            },
            // infoRules: {
            //     nickName: [
            //         { required: true, message: "请输入昵称", trigger: "blur" },
            //         { max: 30, message: "最大长度为30个字符", trigger: "blur" }
            //     ]
            // },
            activeName: "first",
            loading: true,
            uploadActionUrl: Config.baseUrl + "/api/upload/headimg",
            imgUrl: "", //头像
            // vipName:"",//会员名称
            // vipPhote:"",//会员手机号
            // caigouName:"",//采购负责人
            // caigouPhote:"",//采购负责人手机号
            yes: true
        }
    },
    created() {
        this.getInfo();
        this.bookInfo();
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    methods: {
        getInfo() {
            info()
                .then((res) => {
                    if (res.code == 0) {
                        // console.log(res.data.manager_detail)
                        this.memberInfo.userHeadImg = res.data.headimg
                        this.memberInfo.userName = res.data.username
                        this.memberInfo.nickName = res.data.nickname
                        this.memberInfo.email = res.data.email
                        this.memberInfo.tell = res.data.mobile
                        this.memberInfo.caigou_ren = res.data.caigou_ren
                        this.memberInfo.caigou_ren_phone = res.data.caigou_ren_phone
                        this.memberInfo.unconfirmed = res.data.membwe_order_status.virify_order
                        this.memberInfo.unpaid = res.data.membwe_order_status.wait_pay_order
                        this.memberInfo.unshipped = res.data.membwe_order_status.wait_fa_order
                        //this.memberInfo.uncomplete = res.data.membwe_order_status.uncomplete
                        this.memberInfo.favorites = res.data.membwe_order_status.collection_goods
                        this.memberInfo.fivemonth = res.data.membwe_order_status.five_wan_order
                        this.memberInfo.allmoney = res.data.membwe_order_status.finish_order_price

                        this.memberInfo.manager = res.data.manager_detail.username
                        this.memberInfo.managerPhone = res.data.manager_detail.user_phone
                        this.memberInfo.managerQQ = res.data.manager_detail.user_qq
                        this.memberInfo.manageremail = res.data.manager_detail.user_email
                        this.memberInfo.registerTime = res.data.reg_time
                        this.memberInfo.levelName = res.data.member_level_name
                        this.memberInfo.lastLoginTime = res.data.last_login_time
                    }
                    this.loading = false
                    console.log(res.message)
                })
                .catch((err) => {
                    if (err.message == "您尚未登录，请先进行登录") {
                        this.$router.push("/login")
                    } else {
                        this.$message.error(err.message)
                        console.log(err.message)
                    }
                    this.loading = false
                })
        },
        //处理于订单
        bookInfo() {
          info({order_type:5})
              .then((res) => {
                if (res.code == 0) {
                  this.memberInfo.uncomplete = res.data.membwe_order_status.uncomplete
                }
                this.loading = false
              })
              .catch((err) => {
                if (err.message == "您尚未登录，请先进行登录") {
                  this.$router.push("/login")
                } else {
                  this.$message.error(err.message)
                  console.log(err.message)
                }
                this.loading = false
              })
        },
        handleClick(tab, event) {},
        saveInfo() {
            this.$refs.infoRef.validate((valid) => {
                if (valid) {
                    nickName({ nickname: this.memberInfo.nickName })
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message({ message: "修改昵称成功", type: "success" })
                                this.getInfo
                                this.$store.dispatch("member/member_detail", { refresh: 1 })
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err.message)
                        })
                } else {
                    return false
                }
            })
        },
        handleAvatarSuccess(res, file) {
            this.imgUrl = res.data.pic_path
            this.memberInfo.userHeadImg = URL.createObjectURL(file.raw);
            this.uploadHeadImg();
        },
        uploadHeadImg() {
            headImg({
                headimg: this.imgUrl,
                userName: this.memberInfo.userName,
                nickName: this.memberInfo.nickName,
                mobile: this.memberInfo.tell,
                caigou_ren: this.memberInfo.caigou_ren,
                caigou_ren_phone: this.memberInfo.caigou_ren_phone
            })
                .then((res) => {
                    if (res.code == 0) {
                        this.$message({ message: "修改成功", type: "success" })
                        this.$store.dispatch("member/member_detail", { refresh: 1 })
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                })
        },
        // 跳转页面订单页面
        goToOrderList() {
            this.$router.push("/member/order_list")
        },
        // 跳转预订单页面
        goToBookList() {
            this.$router.push("/member/book_list")
        },
        // 跳转到我的购物车
        goToCart() {
            this.$router.push("/member/cart")
        },
        // 跳转到我的收藏夹
        goToCollection() {
            this.$router.push("/member/collection")
        }
    },
    computed: {
        ...mapGetters(["defaultHeadImage", "cartCount"])
    },
    filters: {
        formatDate: function (value) {
            let date = new Date(value * 1000)
            let y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? "0" + MM : MM
            let d = date.getDate()
            d = d < 10 ? "0" + d : d
            let h = date.getHours()
            h = h < 10 ? "0" + h : h
            let m = date.getMinutes()
            m = m < 10 ? "0" + m : m
            let s = date.getSeconds()
            s = s < 10 ? "0" + s : s
            return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s + " "
        }
    }
}
</script>
<style>
.member-info .el-upload {
    /* display: flex;
    justify-content: center; */
    /* margin-bottom: 20px; */
}
</style>
<style lang="scss" scoped>
.box {
    width: 90%;
    position: relative;
}

.null-page {
    width: 100%;
    height: 730px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.member-info {
    background: #ffffff;
    padding: 20px;
    width: 100%;
    .el-tab-pane {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        align-content: center;
        .preview {
            // display: flex;
            // justify-content: center;
            margin-bottom: 20px;
            float: left;
            .title {
                margin-right: 20px;
                line-height: 3;
            }
            .content {
                color: $base-color;
                line-height: 1.5;
            }
        }
        .upload {
            display: flex;
            justify-content: center;
        }
        .el-upload {
            width: 120px;
        }
        .img-wrap {
			// border-radius: 50%;
			//     width: 50px;
			//     height: 40px;
            width: 50px;
            height: 120px;
            display: block;
            line-height: 120px;
            overflow: hidden;
            border: 1px solid #f1f1f1;
            cursor: pointer;
            img {
                display: inline-block;
            }
        }
        .el-form {
            margin-top: 20px;
            width: 500px;
            margin-left: 200px;
            .toBind {
                cursor: pointer;
                &:hover {
                    color: $base-color;
                }
            }
        }
        .btn {
            text-align: center;
            // margin-left: 200px;
            float: left;
        }
    }
    .img-wrap {
        width: 50px;
        margin: 20px auto 0px;
    }
    .upload {
        float: left;
        //margin-right: 30px;
        height: 240px;
    }
    .user-info {
        p {
            float: left;
            margin: 10px;
            label {
                // margin-right: 100px;
                font-size: 16px;
            }
        }
    }
    table {
        border-collapse: separate;
        border-spacing: 15px;
    }
    tr {
    }
    td {
        line-height: 48px;
        font-size: 15px;
    }
    input {
        width: 140px;
        height: 40px;
        float: right;
        margin: 0 20px;
    }
    .order-info {
        padding: 20px 30px;
        width: 210px;
        // height: 153px;
        // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
        border: 1px solid #f1f1f1;
        border-radius: 12px;
        margin: 0 5px;
        img {
            float: left;
            width: 80px;
            height: 80px;
            margin-right: 6px;
            margin-top: 7px;
        }
        p {
        }
    }
}
.last_time {
    float: right;
    margin-top: 40px;
}
.number {
    font-size: 30px;
    span {
        font-size: 16px;
    }
}
.explain {
    font-size: 16px;
}
</style>
